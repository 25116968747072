
 

var io = require('socket.io-client');

var socket;

export default class SocketHelper{


    initSocket(){

        const options = {transports: ['websocket'], forceNew: true};
        socket = io(window.location.origin, options);
        //socket = io('http://0xpool.me', options);
  
  
        // Socket events
        socket.on('connect', () => {
        //  console.log('connected to socket.io server');
        });
  
  
        socket.on('disconnect', () => {
        //  console.log('disconnected from socket.io server');
        });
   

      
       return socket    

      //  setInterval(this.update.bind(this),5000);
    }


    emitEvent(evt,args){
        // console.log('emit',evt,args)
        socket.emit(evt,args); 
       
         
    }
 




}
