<template>
  <div class="navbar h-nav">
    <div class="nav-container text-gray-900">
      <div
          v-for="name in buttonNames"
          :key="name"
          @click="onClick(name)"
          class="nav-button font-sans bg-gray-200 border-2 border-gray-500 hover:bg-gray-800 hover:text-gray-200 cursor-pointer capitalize text-center p-2 m-2 inline-block"
          :class="{'bg-green-500': (name === activeSection), 'text-gray-200': (name === activeSection)}"
      >
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorizontalNav',
  props: ['activeSection', 'buttonClickedCallback', 'buttonNamesArray'],
  data() {
    return {
      buttonNames: this.buttonNamesArray
    }
  },
  methods: {
    onClick(buttonName) {
      this.buttonClickedCallback(buttonName)
    }
  }
}
</script>

<style>
.nav-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* adjust the space between buttons */
  justify-content: center; /* center the buttons */
}

.nav-button {
  flex: 1 1 auto;
  min-width: 100px; /* adjust minimum width as needed */
  margin: 5px; /* optional space around buttons */
}


</style>