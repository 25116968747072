



export default class MathHelper {

static rawAmountToFormatted(amount,decimals)
{
  return (amount * Math.pow(10,-1 * decimals)).toFixed(Math.min(decimals, 8));
}



}