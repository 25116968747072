var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"w-full m-2 p-2 flex flex-row",attrs:{"role":"navigation"}},_vm._l((_vm.navConfig.dropdowns),function(item){return _c('div',{key:item.title,staticClass:"w-full lg:w-auto block lg:inline-block"},[_c('t-dropdown',{staticClass:"w-full",scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var mousedownHandler = ref.mousedownHandler;
        var focusHandler = ref.focusHandler;
        var blurHandler = ref.blurHandler;
        var keydownHandler = ref.keydownHandler;
        var isShown = ref.isShown;
return _c('div',{},[_c('button',{staticClass:"flex text-md  p-2 m-1 mx-2   items-center pr-3 bg-transparent focus:outline-none focus:shadow-solid transition duration-150 ease-in-out border-2 border-gray-200",class:{ 'border-gray-300 bg-gray-500 text-white ': isShown },attrs:{"aria-label":"User menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('span',{staticClass:"px-2"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('i',{staticClass:"fas fa-caret-down "})])])}},{key:"default",fn:function(ref){
        var hide = ref.hide;
        var blurHandler = ref.blurHandler;
return _c('div',{staticClass:"origin-top-right absolute right-0 w-56 shadow-lg text-md bg-gray-200 text-gray-900"},_vm._l((item.rows),function(row){return _c('a',{key:row.title,staticClass:"block w-full  text-black border-2 border-gray-100 px-4 py-2 leading-5 hover:bg-purple-400 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out",attrs:{"href":row.url,"target":_vm.getTarget(row.url),"role":"menuitem"},on:{"blur":blurHandler}},[_vm._v(" "+_vm._s(row.title)+" ")])}),0)}}],null,true)})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }