import Vue from 'vue';
import VueTailwind from 'vue-tailwind';
import App from './App.vue';
import router from './router';

import '@fortawesome/fontawesome-free/js/all.js';
import './css/main.css';
import './css/normalize.css';
import './css/webflow.css';
import './css/custom.webflow.css';
import './css/whitespace.css';

Vue.config.productionTip = false;
Vue.use(VueTailwind);

// Domain check logic
const allowedDomains = ['0xpool.io', 'www.0xpool.io', 'eu.0xpool.io', 'ap.0xpool.eu', 'us.0xpool.io', 'testnet.0xpool.io'];

// Current hostname and protocol
const currentHostname = window.location.hostname;
const currentProtocol = window.location.protocol;

if (currentHostname !== 'localhost' && currentHostname !== 'testnet.0xpool.io') {
  // Check if domain is allowed
  if (!allowedDomains.includes(currentHostname)) {
    // Redirect to the preferred domain with https
    window.location.href = `https://0xpool.io${window.location.pathname}${window.location.search}`;
  } else if (currentProtocol === 'http:') {
    // Redirect to https if on allowed domain but protocol is http
    window.location.href = `https://${currentHostname}${window.location.pathname}${window.location.search}`;
  } else {
    // Initialize Vue if the domain is allowed
    new Vue({
      router,
      render: h => h(App)
    }).$mount('#app');
  }
} else {
  // Initialize Vue on localhost
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app');
}


