<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div ></div>
        <div class="modal-container">

          <slot name="header">
            <div class="modal-header">
              <div v-if="connected"> Set Currency Priority </div>
              <div v-else> Connect To Wallet </div>
              <p>{{address}}</p>
            </div>

          </slot>
        </div>

        <div class="modal-body">
          <slot name="body">
            <div class="modal-content">
              <p class="error-msg" v-if="!connected">Please Login first</p>
              <div>
              <select v-model="communityfee" class="select">
                <option v-for="option in this.priority" v-bind:value="option._id" :key="option.displayName" class="option">
                  {{ option.displayName }}
                </option>
              </select>
              </div>
            </div>
            <div id="errormsg"></div>

          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button class="modal-left-button footer-btn" id="submitButton" @click="submit()" :disabled="!connected">
              SUBMIT
            </button>
            <button class="modal-default-button footer-btn" id="close-btn" @click="$emit('close')">
              CLOSE
            </button>
          </slot>
        </div>
      </div>
    </div>

  </transition>
</template>
<script>
import Moralis from './plugins/moralis'




export default {
  name: "OpenPriorityModal",
  props: ["address"],
  data(){
    return {
      showModal: false,
      auth: null,
      connectbtn:false,
      communityfee: null,
      connected:false,
      selectedpool: { "jsonrpc": "2.0", "params": {}},
      signature: null,
      errorMessages: "",
      showmsg:false,
      chainId: null,
      web3: null,
      accounts: null,
      priority: 1,
      ethAddress: ''

    }
  },  created() {
    this.priority = [{_id: "1", displayName: "BNBTC"}, {_id: "2", displayName: "ETI"}]
    this.communityfee = this.priority[0]._id
    if (Moralis.User.current().attributes.ethAddress === this.address)
    {
      this.connected=true
    }
  },
  mounted(){

  },
  methods:{

    async submit(){
      // eslint-disable-next-line no-unused-vars
      await Moralis.Cloud.run("setPriority", {data:this.communityfee}).then((response) => {
        //console.log(response)
        document.getElementById('close-btn').click();
      })
          .catch(error => {
            console.log(error);
            document.getElementById("errormsg").innerHTML = error;
            console.error("There was an error!", error);
          });
    }

  }
}
</script>
<style scoped >
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
#errormsg {
  position: relative;
  top: 30px;
  color: red;
}
.error-msg {
  color: red;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  background: #32333c;
  width: 500px;
  border-radius: 11px;
}
.connect-btn{
  border: 2px solid #e2e8f0;
  border-radius: 5px;
  padding: 9px;
  box-shadow: 2px 10px 10px 2px #212121;
}

.modal-container {
  width: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  border-bottom: 2px solid #e2e8f0;
  background: #1a202b;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.select {
  background: transparent;
  -webkit-appearance: none;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  padding: 9px;
  box-shadow: 2px 10px 10px 2px #212121;
}

.option {
  /* Whatever color  you want */
  background-color: #333;
}


.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 20px;
}
.modal-footer{
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-btn {
  border: 2px solid #e2e8f0;
  border-radius: 5px;
  padding: 8px;
  box-shadow: 2px 10px 10px 2px #212121;
  background: #1a202b;
  margin: 0 14px;
}
.modal-content{
  display: flex;
  justify-content: space-around;
}
.modal-content input{
  color: #000;
}
.community-text{
  text-align: center;
}
.modal-default-button {
  float: right;
}
::placeholder {
  text-align: center;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
