<template>
<div>
        <Navbar />
     

   <div class="section bg-slate   ">
     <div class="w-container  pt-8 text-gray-100 " style="max-width: 1360px;">
            <h1 class="title text-lg text-gray-100">
              Mining Account List
            </h1>
            <h2 class=" ">
             
            </h2>
             <div class="whitespace-sm">
               <div class="heading-section">
                 <div class="left-heading-section">
                  <img src="../assets/images/pool-logo1.png" alt="">
                      <!-- <h2>BNbitcoin</h2> -->
                 </div>
                 <div class="right-heading-section">
                  <img src="../assets/images/etica_wtext_0xpool2.png" alt="">
                    <!-- <h2>0xbitcoin</h2> -->
                 </div>
               </div>
             </div>
      <div   class="table-box-main box background-secondary" style="  min-height:480px; ">
        <div class='subtitle'> </div>
        <table class='table w-full' id="table-heading">

          <thead>
            <tr class="table-head">
              <th class="px-1 first-column" > Miner # </th>

              <th class="px-1"> Address </th>
              <th class="px-1"> BNBTC GH/s </th>
              <th class="px-1 bnbtc-back-img"> BNBTC Donated </th>
              <th class="px-1"> BNBTC Earned </th>
              <th class="px-1"> BNBTC Paid </th>
              <th class="px-1 xbtc-0"> ETI KH/s </th>
              <th class="px-1 bnbtc-back-img"> ETI Donated </th>
              <th class="px-1"> ETI Earned </th>
              <th class="px-1"> ETI Paid </th>

            </tr>
          </thead>

          <tbody class="table-data">

            <tr v-for="(item, index) in accountList" :key=index>
              <td class="px-1 first-column">  Miner {{ index }} </td>


                <td>
                      <a v-bind:href='"/profile/"+item.minerEthAddress' @click="updateEthAddress(item.minerEthAddress)">
                        <span>  {{ item.minerEthAddress }}  </span>
                      </a>
                </td>

              <td class="px-1"> {{ hashrateToMH(item.bscAvgHashrate) }} </td>
              <td class="px-1"> {{ tokensRawToFormatted( item.bscTokensDonated,8 ) }}   </td>
              <td class="px-1"> {{ tokensRawToFormatted( item.bscAlltimeTokenBalance,8) }}    </td>
              <td class="px-1"> {{ tokensRawToFormatted( item.bscTokensAwarded,8 ) }}   </td>
              <td class="px-1 xbtc-0"> {{ item.eticaAvgHashrate.toFixed(2) || "0.00"}} </td>
              <td class="px-1"> {{ tokensRawToFormatted( item.eticaTokensDonated,18 ) }}   </td>
              <td class="px-1"> {{ tokensRawToFormatted( item.eticaAlltimeTokenBalance,18) }}    </td>
              <td class="px-1"> {{ tokensRawToFormatted( item.eticaTokensAwarded,18 ) }}   </td>


             
            </tr>


          </tbody>
        </table>

      </div>
     </div>
   </div>
<Footer/>

</div>
</template>


<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
 

import SocketHelper from '../js/socket-helper'
import MathHelper from '../js/math-helper'

import web3utils from 'web3-utils'

export default {
  name: 'Accounts',
  props: [],
  components: {Navbar,Footer},
  data() {
    return {
       
      accountList: [] 
    }
  },
  created(){
    
     this.socketHelper = new SocketHelper()
    
     setInterval(this.pollSockets.bind(this),5000)


    this.socketsListener = this.socketHelper.initSocket()
    
    
    this.socketsListener.on('minerList', (data) => {               
       
         
          this.updateAccountList(data)

    });



   this.pollSockets()

  },
  mounted(){
    document.getElementById('table-heading').floatThead({top:50});

  },
  beforeDestroy(){
    this.socketsListener.removeAllListeners()
  },
  methods: {


      async updateAccountList(newList){
 
           this.accountList = newList 
    
         /* this.accountList.push({
            alltimeTokenBalance: 100,
            minerEthAddress: '0x000000',
            tokenBalance: 100000,
            tokensAwarded: 0

          } )*/

          // console.log('this.accountList', this.accountList)

           this.accountList = this.accountList.filter(x => web3utils.isAddress( x.minerEthAddress ) )



          //this.accountList.sort((a,b) => {return b.alltimeTokenBalance - a.alltimeTokenBalance})
        //this.accountList.sort((a,b) => {return b.tokensDonated - a.tokensDonated})

      },
      pollSockets(){
       
          this.socketHelper.emitEvent( 'getMinerList')
      },
      hashrateToMH(hashrate){

        return parseFloat(MathHelper.rawAmountToFormatted( hashrate , 6 )).toFixed(2)
      },
      tokensRawToFormatted(rawAmount, decimals){
          if(isNaN(rawAmount)){rawAmount = 0 }
          return MathHelper.rawAmountToFormatted( rawAmount , decimals )
      },
      updateEthAddress(address){
        window.ethaddress = address;
      }

  }
}
</script>
<style  scoped>
.table-head .px-1 {
    font-size: 20px;
    font-weight: 800;
    
}
tr.table-head {
    border-bottom: 2px solid;
}
.px-1{
    width: 90px;
    text-align: center;
    padding: 8px;
}
.w-full .first-column {
    text-align: left;
}
.whitespace-sm {
    height: 100px;
    margin-top: 0;
}
.heading-section {
    width: 64%;
    float: right;
    height: 100px;
    display: flex;
}

.left-heading-section {
    width: 50%;
    display: flex;
    align-items: center;
}

.right-heading-section {
    width: 50%;
    display: flex;
    align-items: center;
}

.heading-section img {
    height: 80px;
    padding-left: 10px;
}

.heading-section h2 {
    font-size: 45px;
    font-family: monospace;
    padding-left: 15px;
}
.w-full .xbtc-0 {
    padding-left: 48px !important;
}
.table-box-main .table-head th {
    position: sticky;
    top: 0;
    background: #32333c;
    z-index: 100;
        height: 82px;
}
.table-box-main {

}
@media screen and (max-width: 800px) {
.px-1{
  min-width: 113px;
}
.heading-section {
    width: 100%;
}
.table-box-main{
  overflow-x: auto;
max-height: 700px;
}
}
</style>