<template>

<div>



 
        <Navbar />
     

   <div class="section bg-slate  text-white  pb-8">
     <div class="w-container pt-8">

       
 

      <h1 class="title font-primary-title color-primary mb-4">
        Pool Overview
      </h1>
      

      
      <HorizontalNav 
          class="mb-8"
         v-bind:activeSection="activeSection" 
         v-bind:buttonClickedCallback="onHorizontalNavClicked" 
         v-bind:buttonNamesArray="['Status','Mining Data','BSC Transactions', 'ETI Transactions' ]"
   
       />



        <div v-if="poolStatus && activeSection==='Status'" class="overflow-x-auto  mb-4">
          <h3 class="title font-secondary-title color-primary mb-4">
            BNBTC Pool Status
          </h3>
          <div class="mb-4">
            <div>BNBTC Pool Status: {{poolStatus.bsc.poolStatus}}</div>
            <div v-if="poolStatus.bsc.poolStatus!=='active'">Suspension Reason: {{poolStatus.bsc.suspensionReason}}</div>
          </div>


          <div class="mb-4">avgGasPriceGWei: {{poolStatus.bsc.poolFeesMetrics.avgGasPriceGWei}}</div>
          
          
          <div>Full Mining Reward: {{Number.parseFloat(rawAmountToFormatted(poolStatus.bsc.poolFeesMetrics.miningRewardRaw,8))}}</div>

          <div>miningRewardInBNB: {{poolStatus.bsc.poolFeesMetrics.miningRewardInEth}}</div>
          <div>bnbRequiredForMint: {{poolStatus.bsc.poolFeesMetrics.ethRequiredForMint}}</div>

           <div>poolBaseFeeFactor: {{poolStatus.bsc.poolFeesMetrics.poolBaseFee}}</div>
           <div>gasCostFeeFactor: {{poolStatus.bsc.poolFeesMetrics.gasCostFee}}</div>

          <div>overallFeeFactor: {{Number.parseFloat(poolStatus.bsc.poolFeesMetrics.overallFeeFactor).toFixed(4)  }}</div>


          <h3 class="title font-secondary-title color-primary mb-4">
            ETI Pool Status
          </h3>
          <div class="mb-4">
            <div>ETI Pool Status: {{poolStatus.etica.poolStatus}}</div>
            <div v-if="poolStatus.etica.poolStatus!=='active'">Suspension Reason: {{poolStatus.etica.suspensionReason}}</div>
          </div>


          <div class="mb-4">avgGasPriceGWei: {{poolStatus.etica.poolFeesMetrics.avgGasPriceGWei}}</div>


          <div>Full Mining Reward: {{Number.parseFloat(rawAmountToFormatted(poolStatus.etica.poolFeesMetrics.miningRewardRaw,18))}}</div>

<!--          <div>miningRewardInETH: {{poolStatus.etica.poolFeesMetrics.miningRewardInEth}}</div>-->
          <div>ethRequiredForMint: {{poolStatus.etica.poolFeesMetrics.ethRequiredForMint}}</div>

<!--          <div>poolBaseFeeFactor: {{poolStatus.etica.poolFeesMetrics.poolBaseFee}}</div>-->
          <div>poolBaseFeeFactor: 0.01</div>
<!--          <div>gasCostFeeFactor: {{poolStatus.etica.poolFeesMetrics.gasCostFee}}</div>-->

<!--          <div>overallFeeFactor: {{Number.parseFloat(poolStatus.etica.poolFeesMetrics.overallFeeFactor).toFixed(2)  }}</div>-->
          <div>overallFeeFactor: 0.01</div>



        </div> 

        <div v-if="poolData && poolStatus && activeSection==='Mining Data'" class="overflow-x-auto mb-4">
          <h3 class="title font-secondary-title color-primary mb-4">
            BNBTC Mining Data
          </h3>
            <div class="my-4">
              <div>Minting Account Address: {{poolData.bsc.mintingAddress}}</div>
              <div>Minting Network Name: {{poolData.bsc.mintingNetwork}}</div>
              <div v-if="poolStatus.bsc.mintingAccountBalances">Minting Balance BNB: {{rawAmountToFormatted(poolStatus.bsc.mintingAccountBalances['ETH'] , 18 ) }}</div>
              <div v-if="poolStatus.bsc.mintingAccountBalances">Minting Balance Token: {{rawAmountToFormatted(poolStatus.bsc.mintingAccountBalances['token'], 8)}}</div>
            </div>
            <div class="my-4">
              <div>Payments Accounts Address: {{poolData.bsc.paymentsAddress}}</div>
              <div>Payments Network Name: {{poolData.bsc.paymentsNetwork}}</div>
              <div v-if="poolStatus.bsc.paymentsAccountBalances">Payments Balance BNB: {{rawAmountToFormatted(poolStatus.bsc.paymentsAccountBalances['ETH'],18)}}</div>
              <div v-if="poolStatus.bsc.paymentsAccountBalances">Payments Balance Token: {{rawAmountToFormatted(poolStatus.bsc.paymentsAccountBalances['token'], 8)}}</div>

              <div v-if="poolData.bsc.batchedPaymentsContractAddress">Batched Payments Contract Address: {{poolData.bsc.batchedPaymentsContractAddress}}</div>
              <div >Tokens Approved to Batched Payments: {{rawAmountToFormatted(poolStatus.bsc.tokensApprovedToBatchPayments,8)}}</div>
            </div>
            <div class="my-4">
              <div>Last Known Block Number: {{poolData.bsc.ethBlockNumber}}</div>
              <div v-if="poolData.bsc.minBalanceForPayment > 0">Minimum User Balance For Payment: {{rawAmountToFormatted(poolData.bsc.minBalanceForPayment,8)}}</div>
              <div v-if="poolStatus.bsc.minBalanceForManualTransfer > 0">Minimum User Balance For Manual Payment: {{rawAmountToFormatted(poolStatus.bsc.minBalanceForManualTransfer,8)}}</div>
            </div>
        </div>


         <div v-if="poolData && poolData.bsc.miningContract && activeSection==='Mining Data'" class="overflow-x-auto  mb-4">
           
            <div>Current Challenge Number: {{poolData.bsc.miningContract.challengeNumber}}</div>

            <div>Full Mining Difficulty: {{poolData.bsc.miningContract.miningDifficulty}}</div>



           <h3 class="title font-secondary-title color-primary mb-4">
             ETI Mining Data
           </h3>
           <div class="my-4">
             <div>Minting Account Address: {{poolData.etica.mintingAddress}}</div>
             <div>Minting Network Name: {{poolData.etica.mintingNetwork}}</div>
             <div v-if="poolStatus.etica.mintingAccountBalances">Minting Balance EGAZ: {{rawAmountToFormatted(poolStatus.etica.mintingAccountBalances['ETH'] , 18 ) }}</div>
             <div v-if="poolStatus.etica.mintingAccountBalances">Minting Balance Token: {{rawAmountToFormatted(poolStatus.etica.mintingAccountBalances['token'], 18)}}</div>
           </div>
           <div class="my-4">
             <div>Payments Accounts Address: {{poolData.etica.paymentsAddress}}</div>
<!--             <div>Payments Network Name: {{poolData.eth.paymentsNetwork}}</div>-->
             <div v-if="poolStatus.etica.paymentsAccountBalances">Payments Balance EGAZ: {{rawAmountToFormatted(poolStatus.etica.paymentsAccountBalances['ETH'],18)}}</div>
<!--             <div v-if="poolStatus.eth.paymentsAccountBalances">Payments Balance Token: {{rawAmountToFormatted(poolStatus.eth.paymentsAccountBalances['token'], 8)}}</div>-->

<!--             <div v-if="poolData.eth.batchedPaymentsContractAddress">Batched Payments Contract Address: {{poolData.eth.batchedPaymentsContractAddress}}</div>-->
<!--             <div >Tokens Approved to Batched Payments: {{rawAmountToFormatted(poolStatus.eth.tokensApprovedToBatchPayments,8)}}</div>-->
           </div>
           <div class="my-4">
             <div>Last Known Block Number: {{poolData.etica.ethBlockNumber}}</div>
             <div v-if="poolData.etica.minBalanceForPayment > 0">Minimum User Balance For Payment: {{rawAmountToFormatted(poolData.etica.minBalanceForPayment,18)}}</div>
             <div v-if="poolStatus.etica.minBalanceForManualTransfer > 0">Minimum User Balance For Manual Payment: {{rawAmountToFormatted(poolStatus.etica.minBalanceForManualTransfer,18)}}</div>
           </div>
         </div>


       <div v-if="poolData && poolData.etica.miningContract && activeSection==='Mining Data'" class="overflow-x-auto  mb-4">

         <div>Current Challenge Number: {{poolData.etica.miningContract.challengeNumber}}</div>

         <div>Full Mining Difficulty: {{poolData.etica.miningContract.miningDifficulty}}</div>


        </div>








        <div v-if="activeSection ==='BSC Transactions'" class="mb-4">
          <h3 class="title font-secondary-title color-primary mb-4">
            BNBTC Recent Transactions
          </h3>

         <section>
       <TransactionsTable
        class="mb-4"
        label="Recent Solutions" 
        v-bind:transactionsList="recentSolutionTx.bsc"
        />
        </section>
        <section>
          <TransactionsTable
            class="mb-4"
            label="Recent Payments"
            v-bind:transactionsList="recentPaymentTx.bsc"
          />
        </section>
        </div>


       <div v-if="activeSection ==='ETI Transactions'" class="mb-4">
          <h3 class="title font-secondary-title color-primary mb-4">
            ETI Recent Transactions
         </h3>

         <section>
           <TransactionsTable
               class="mb-4"
               label="Recent Solutions"
               v-bind:transactionsList="recentSolutionTx.etica"
           />
         </section>
         <section>
           <TransactionsTable
               class="mb-4"
               label="Recent Payments"
               v-bind:transactionsList="recentPaymentTx.etica"
           />
         </section>

       </div>

       

 


     </div>


    
   </div>

   

 
    

  <Footer    />

</div>
</template>


<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
 

import TransactionsTable from './components/TransactionsTable.vue';


import HorizontalNav from './components/HorizontalNav.vue';

import SocketHelper from '../js/socket-helper'

import MathHelper from '../js/math-helper'

export default {
  name: 'Accounts',
  props: [],
  components: {Navbar,Footer,TransactionsTable, HorizontalNav},
  data() {
    return {
      poolData: null,
      poolStatus: null,

      activeSection: 'Status',

       
      accountList: [] ,

      recentSolutionTx:[],
      recentPaymentTx:[] 
    }
  },
  created(){
     this.socketHelper = new SocketHelper()
      
      setInterval(this.pollSockets.bind(this),5000)


      this.socketsListener = this.socketHelper.initSocket()
     
     
       this.socketsListener.on('poolData', (data) => {   
            this.poolData = data
         //console.log("poolData",this.poolData)
        });

         this.socketsListener.on('poolStatus', (data) => {   
            this.poolStatus = data
           //console.log("poolStatus",this.poolStatus)
        });


         this.socketsListener.on('recentSolutions', (data) => {
            this.recentSolutionTx=data
           this.recentSolutionTx.bsc.map( x => this.addExplorerUrl(x, 'bsc')  )
           this.recentSolutionTx.etica.map( x => this.addExplorerUrl(x, 'etica')  )

           //console.log("recentSolutionTx",this.recentSolutionTx)
        });

         this.socketsListener.on('recentPayments', (data) => {  
            this.recentPaymentTx=data
           this.recentPaymentTx.bsc.map( x => this.addExplorerUrl(x, 'bsc')  )
           this.recentPaymentTx.etica.map( x => this.addExplorerUrl(x, 'etica')  )
           //console.log("recentPaymentTx",this.recentPaymentTx)
        });

      this.pollSockets()
  },
  methods: {
    pollSockets(){
      this.socketHelper.emitEvent('getPoolData')
      this.socketHelper.emitEvent('getPoolStatus')
      this.socketHelper.emitEvent('getRecentSolutions')
      this.socketHelper.emitEvent('getRecentPayments')
    },

    rawAmountToFormatted(amount, decimals){
      return MathHelper.rawAmountToFormatted(amount,decimals)
    },

    onHorizontalNavClicked(item){
     
      this.activeSection = item


    },

    addExplorerUrl(txData, chain){

      if(!this.poolData) return ;

      let baseURL

      if(chain === 'bsc') {
        baseURL =  'https://bscscan.com/tx/'
      }else {
        baseURL =  'https://eticascan.org/tx/'
      }
      txData.txURL=baseURL.concat(txData.txHash)
    },

 

  }
}
</script>
