<template>
  <div>
    <Navbar />

    <div class="m-2 faq-page">
      <div class="inline-block bg-gray-800 p-2 text-white container-faq">
        <p class="heading-faq">Frequently Asked Questions</p>
        <hr />
        <p class="question">Q: How does the ETI reward system work?</p>
        <p>A: All shares submitted within an specific period of time will be credited as share credits.</p>
        <p>When a reward period ends, all accumulated ETIs will be calculated and divided through the total amount of share credits awarded during that reward period.</p>
        <p>Each miner gets rewarded according to the number of accumulated share credits then.</p>
        <p></p>
        <hr />
        <p class="question">Q: Why don't my tokens show up in my wallet?</p>
        <p>A: You might have not met the required token threshold for payout.</p>
        <p>Please make sure you met the required token threshold listed on <a href="https://0xpool.io/overview">https://0xpool.io/overview</a> -> Mining Data -> Minimum User Balance For Payment</p>
        <hr />
        <p class="question">Q: How to pay out?</p>
        <p>A: Your tokens will be automatically sent to your wallet as soon you meet the minimum user balance for payment</p>
        <hr />
        <p class="question">Q: How to change your preferred mining currency?</p>
        <p>A: You can change the preferred currency on your accounts page.</p>
        <hr />
        <p class="question">Q: Can I mine to exchange address?</p>
        <p>A: No. Please do not try to mine to an exchange address.</p>
        <p>Whether incoming nor outgoing payment will be processed by the pool or the exchange!</p>
        <hr />
        <p class="question">Q: Whats the (optional) community fee is good for?</p>
        <p>A: This fee will directly be credited to the BNBTC development funds wallet.</p>
        <p>This wallet will be used on community decision to pay expenses for further BNBTC development.</p>
        <p>
          The funds will
          <b>NOT</b> be credited to the pool operator.
        </p>
        <p>See the news below for details.</p>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar";
export default {
  components: {
    Navbar
  }
};
</script>
<style scoped>
.container-faq {
  max-width: 940px;
  margin: 0 auto;
  padding: 20px;
}
.faq-page {
  display: flex;
  margin: 50px auto;
}
.heading-faq {
  font-size: 20px;
  font-family: monospace;
}
hr {
  margin-bottom: 20px;
}
p.question {
  font-weight: 800;
}
@media screen and (max-width: 800px) {
  .faq-page {
    margin: 0px auto;
  }
}
</style>