<template>
  <transition name="modal1">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div></div>
        <div class="modal-container">
          <slot name="header">
            <div class="modal-header">
              <div v-if="connected">Enter Swap amount</div>
              <div v-else>Please Login first</div>
              <p>{{address}}</p>
            </div>
          </slot>
        </div>

        <div class="modal-body">
          <slot name="body">
            <div class="modal-content">
              <div class="amount-section">
                <label for>Enter Amount: &nbsp;&nbsp;&nbsp;</label>
                <input
                    type="number"
                    v-model="amount"
                    class="amount-input"
                    @change="disableSubmit"
                    @keyup="validateNumber()"
                    @keydown="validateNumber()"
                    :class="checktoken()?'':'amount-invalid'"
                />
              </div>
              <div class="swap-destination">
                <label class="swap-desti">Select Destination:&nbsp;&nbsp;</label>
                <fieldset id="selection">
                  <input type="radio" value="Deposit to Pool" v-model="transaction" name="group1" />Deposit to Pool
                  <input type="radio" value="Send to Wallet" v-model="transaction" name="group1"  />Send to Wallet
                </fieldset>
              </div>

              <button
                  class="connect-btn"
                  @click="getQuotationnData()"
                  v-if="connected && amount!= 0"
                  :disabled="amount <= 0 || amount > tokenbalance"
              >Get Quotation</button>
              <div id="errormsgs"></div>

              <div id="showData">
                <p id="toTokenAmount"></p>
                <p id="tx-price"></p>
              </div>
            </div>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button
                class="modal-left-button footer-btn"
                id="submitbtn"
                @click="submit()"
                :disabled="getEnabled()"
            >SUBMIT</button>
            <button
                class="modal-default-button footer-btn"
                id="close-btn"
                @click="$emit('close')"
            >CLOSE</button>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import axios from "axios";
import Moralis from "./plugins/moralis";

export default {
  name: "RequestPayout",
  props: ["address", "tokenbalance"],
  data() {
    return {
      showModal: false,
      auth: null,
      connectbtn: false,
      communityfee: null,
      connected: false,
      updatefee: { jsonrpc: "2.0", params: {} },
      signature: null,
      errorMessages: "",
      showmsg: false,
      chainId: null,
      transaction: null,
      amount: 0,
      web3: null,
      accounts: null,
      dataswap: null,
      index :0,
      //   txPrice: ''
    };
  },
  mounted() {
    if (Moralis.User.current())
    {
      this.connected=true
    }
  },
  methods: {
    disableSubmit(){
      // alert("changed amount");
      // if(this.index != 0){
      document.getElementById("errormsgs").innerHTML= "Please Get Quotation again";
      document.getElementById("submitbtn").disabled = true;
      // this.index++;
      // }

    },
    validateNumber() {
      // this.disableSubmit();
      this.getQuotationnData();
      var len = this.amount.length;
      var index = this.amount.indexOf(".");
      var CharAfterdot = len + 1 - index;
      if (CharAfterdot > 9) {
        this.amount = parseFloat(this.amount).toFixed(8);
      }
    },
    checktoken() {
      if (
          parseFloat(this.amount) >= parseFloat(this.tokenbalance) ||
          parseFloat(this.amount) <= 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    getEnabled() {
      console.log(this.transaction);
      window.transact = this.transaction;
      if (this.connected===true) {
        return false;
      }else if(this.transaction === null){
        return true;
      }else{
        return true;

      }
    },
    async getQuotationnData() {
      let url =
          "https://api.1inch.exchange/v4.0/1/quote" +
          "?fromTokenAddress=" +
          "0xb6ed7644c69416d67b522e20bc294a9a9b405b31" +
          "&toTokenAddress=" +
          "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" +
          "&amount=" +
          parseInt(this.amount * Math.pow(10, 8));
      document.getElementById("errormsgs").innerHTML = "";
      var fastprice = await this.getFastprice();
      if (this.connected===false) {
        document.getElementById("errormsgs").innerHTML =
            "Please Login first";
      }

      return axios
          .get(url)
          .then(function(response) {
            if (fastprice != undefined)
              console.log(fastprice);
            document.getElementById("showData").classList.add("desing-section");
            document.getElementById("toTokenAmount").innerHTML =
                "toTokenAmount:  " +
                response.data.toTokenAmount /
                Math.pow(10, response.data.toToken.decimals);
            console.log(parseInt(fastprice) * parseInt(response.data.estimatedGas));
            var txprice = parseInt(fastprice) * parseInt(response.data.estimatedGas);

            if (fastprice === undefined || txprice === undefined || isNaN(txprice)){

              document.getElementById("errormsgs").innerHTML= "Could not fetch txprice Please try again "
            }else{
              window.txprice = txprice;
              document.getElementById("tx-price").innerHTML =
                  "tx-price:   " + txprice;
            }


            window.swapdata = response.data;
            if (
                document.getElementById("errormsgs") != "Please Connect to Wallet"
            ) {
              if(document.querySelector( 'input[name="group1"]:checked') == null){
                document.getElementById("submitbtn").disabled = true;
              }else{
                document.getElementById("submitbtn").disabled = false;
              }
              setTimeout(function() {

                document.getElementById("submitbtn").disabled = true;

                document.getElementById("errormsgs").innerHTML =
                    "Please Get Quotation again";
              }, 15000);
            }
          })
          .catch(error => {
            console.log(error);
            document.getElementById("errormsgs").innerHTML = error;
            console.error("There was an error!", error);
          });
    },
    async getFastprice() {
      const fast = await axios.get(
          "https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=B13D8N4AK5BMVTWM97CVETWTYRK6N7KEAE"
      );
      return fast.data.result.FastGasPrice;
    },

    submit() {
      // window.community = window.swapdata;
      // window.address = this.address;
      // window.amount = parseFloat(this.amount);
      // console.log(window.accounts);
      // window.transaction = this.transaction;
      Moralis.Cloud.run("requestSwap", {data:{destination:this.transaction, quote: window.swapdata, tokenAmount: this.amount, txPrice: window.txprice}}).then((/*response*/) => {
        //console.log(response)
        document.getElementById('close-btn').click();
      })
          .catch(error => {
            console.log(error);
            document.getElementById("errormsg").innerHTML = error;
            console.error("There was an error!", error);
          });
    },
    getdisabled(amount, transaction) {
      if (amount !== null && amount !== "" && this.checktoken()) {
        return false;
      } else if (transaction !== null) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
<style scoped >
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.amount-invalid {
  border: 2px solid red;
}
#errormsgs {
  margin-top: 15px;
  color: red;
  height: 22px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  background: #32333c;
  width: 500px;
  border-radius: 11px;
}
.connect-btn {
  border: 2px solid #e2e8f0;
  border-radius: 5px;
  padding: 9px;
  box-shadow: 2px 10px 10px 2px #212121;
  width: 160px;
  margin-top: 16px;
}
.amount-section {
  display: flex;
}
.swap-destination {
  display: flex;
  margin-top: 14px;
}
#selection input {
  position: relative;
  top: 1px;
  margin-right: 3px;
  margin-left: 5px;
}
#showData {
  margin-top: 15px;
  min-height: 84px;
}
.desing-section {
  border: 2px solid;
  border-radius: 8px;
  padding: 8px;
}
.modal-container {
  width: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  border-bottom: 2px solid #e2e8f0;
  background: #1a202b;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0px 0;
  padding: 20px;
}
.modal-footer {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-btn {
  border: 2px solid #e2e8f0;
  border-radius: 5px;
  padding: 8px;
  box-shadow: 2px 10px 10px 2px #212121;
  background: #1a202b;
  margin: 0 14px;
}
.modal-content {
  justify-content: space-around;
}
.modal-content input {
  color: #000;
}
.community-text {
  height: 40px;
  width: 40%;
  text-align: center;
}
.modal-default-button {
  float: right;
}
::placeholder {
  text-align: center;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
