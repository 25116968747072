const config={

  "dropdowns":[
    {"title":"POOL","rows":[
            {"title":"Home","url":"/"},
            {"title":"FAQ","url":"/faq"},
            {"title":"Accounts","url":"/accounts"},
            {"title":"Overview","url":"/overview"},
            {"title":"API","url":"/api"}
    ]},
    {"title":"DOCS","rows":[
      {"title":"BNbitcoin info website","url":"https://bnbitcoin.org"},
      {"title":"Mineions GitHub repos","url":"https://github.com/Mineions"}
    ]},      {"title":"BNBTC","rows":[
              {"title":"Statistics","url":"https://stats.bnbitcoin.org/?page=stats&#"},
              {"title":"Graphs","url":"https://stats.bnbitcoin.org/?page=graphs&#"},
          ]},
      {"title":"ETI","rows":[
              {"title":"Project Homepage","url":"https://www.eticaprotocol.org"},
              {"title":"Eticanomics","url":"https://www.eticanomics.net/"},
          ]}
  ]

}
export default config;
