<template>
  <div class="section footer">
    <div class="container-3 w-container">
      <div class="w-row">
        <div class="column-5 w-col w-col-4 w-col-small-4">
          <h3 class="heading-3 footer">Documentation</h3>


          <a href="https://github.com/BNbitcoin/white-paper" target="_blank" class="footer-link">BNbitcoin white-paper</a>
          <a href="https://bscscan.com/address/0xE7Cb24F449973D5B3520E5b93D88B405903c75Fb#code" target="_blank" class="footer-link">BNbitcoin smart contract</a>
        </div>
        <div class="column-6 w-col w-col-4 w-col-small-4">
          <h3 class="heading-3 footer">Community &amp; Social</h3>
          <a href="https://bnbitcoin.org" target="_blank" class="footer-link">BNbitcoin official GitHub</a>

          <a href="https://discord.gg/E7x9s8UNTK" target="_blank" class="footer-link">BNbitcoin Discord</a>
          <a href="https://www.reddit.com/r/BNbitcoin/" target="_blank" class="footer-link">BNbitcoin Reddit</a></div>
        <div class="column-7 w-col w-col-4 w-col-small-4 flex flex-col h-full">

          <div class="flex-grow h-32">
          </div>


          <div class="text-yellow-400 hidden">
           <span class="text-gray-400"> Powered by </span> <a href=" " target="_blank" class="    ">  </a>
         </div>

         </div>
      </div>
    </div>
  </div>
</template>


<script>


export default {
  name: 'Footer',
  props: [],
  data() {
    return {

    }
  },
  created(){

  },
  methods: {

  }
}
</script>
