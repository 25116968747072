<template>
  <div class="m-6 p-4 bg-gray-800">
    <ul>
      <div v-for="section in filteredSections" :key="section.title">
        <li class="text-lg text-orange-600">{{ section.title }}</li>
        <li v-for="row in section.rows" :key="row.id">
          <a href="#" @click="setContent(row.id)" class="text-orange-200">
            {{ row.title }}
          </a>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
//import Config from 'path-to-your-config-file'; // Adjust the import path accordingly

export default {
  name: 'DocumentationNav',
  props: {
    setContentCallback: { type: Function },
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      navConfig: Config,
    };
  },
  created() {
    this.setContent('welcome');
  },
  computed: {
    filteredSections() {
      return this.navConfig.sections.filter(section => !section.hidden);
    },
  },
  methods: {
    setContent(contentType) {
      console.log('setting content to', contentType);
      this.setContentCallback(contentType);
    },
  },
};
</script>
