<template>
  <div class="section bg-gray-900 px-0 lg:px-1 border-b-2 border-gray-500 main-navbar">
    <div class>
      <div data-collapse="small" data-animation="default" data-duration="400" class="navbar w-nav">
        <div class="bg-transparent">
          <div class="container w-container navbar-container">
            <div class="w-full flex">
              <div class="flex-grow">
                <a href="/" class="brand w-nav-brand w--current">
                  <img src="@/assets/images/logo.png" height="65" alt class="w-6 m-2"/>
                  <!-- <div class="inline-block text-xl text-gray-100">BNbitcoin Mining Pool</div> -->
                </a>
              </div>

              <div class="hidden lg:inline-block text-gray-100" style=" ">
                <UpperNav v-bind:web3Plug="web3Plug"/>
              </div>

              <div class="inline-block lg:hidden pull-right p-4" style=" ">
                <button
                    @click="showResponsiveMenu=!showResponsiveMenu"
                    class="flex items-right px-3 py-2 border rounded text-gray-100 border-teal-400 hover:text-white hover:border-white"
                >
                  <svg
                      class="fill-current h-3 w-3"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                  </svg>
                </button>

                <div
                    class="w-full absolute left-0 block flex-grow lg:flex lg:items-center lg:w-auto bg-gray-500"
                    style="top:60px"
                    :class="{'hidden': !showResponsiveMenu }"
                >
                  <div class="text-sm lg:flex-grow">
                    <AccordionNav v-bind:web3Plug="web3Plug"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="connect-section">
              <div class="public-address">
                <button @click="authenticate()" class="btn-connect" v-if="!connected ">Login</button>
                <button @click="disconnect()" class="btn-connect" v-else>Logout
                </button>
                <p class="public-add" v-if="ethAddress" @click="gotoProfile()">
                  {{ ethAddress.substring(0, 5) + '[...]' + ethAddress.substring(ethAddress.length - 3, ethAddress.length) }}</p>
                <p id="errormsg"></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import Web3NetButton from "./Web3NetButton.vue";

import UpperNav from "./UpperNav.vue";
import AccordionNav from "./AccordionNav.vue";
import Moralis from '../plugins/moralis'


export default {
  name: "Navbar",
  props: ["web3Plug", "web3RefreshCallback", "web3ErrorCallback"],
  components: {UpperNav, AccordionNav},
  data() {
    return {
      showResponsiveMenu: false,
      ethAddress: null,
      connected: false,
      isAuthenticated: false,
      isWeb3Active: false,
      user: null,
      provider: null,
      options: {signingMessage:"0xPool authentication"}
    };
  },
  async mounted() {
    //this.connected = localStorage.getItem('connected');
    // const currentUser = Moralis.User.current();
    // if (currentUser) {
    //   this.connected = true
    //   this.user = currentUser
    //   this.isAuthenticated = true
    //   console.log("current user", currentUser)
    //   console.log("current user eth address",currentUser.get('ethAddress'))
    //   // do stuff with the user
    // }
    const isWeb3Active = await Moralis.ensureWeb3IsInstalled()
    if (isWeb3Active) {
      console.log("Activated");
    }
  },
  async created() {
    Moralis.isMetaMaskInstalled().then(isMetaMaskInstalled => {
          console.log("isMetaMaskInstalled", isMetaMaskInstalled)
          if (!isMetaMaskInstalled) {
            this.options.provider = "walletconnect"
            this.options.projectId = '1a4fc09ad5d7117823ca98cd254b8825'
            this.options.chainId = 56
          }
        }
    );
    const currentUser = Moralis.User.current();
    if (currentUser) {
      this.connected = true
      this.user = currentUser
      this.isAuthenticated = true
      console.log("current user", currentUser)
      console.log("current user eth address", currentUser.get('ethAddress'))
      this.ethAddress = currentUser.get('ethAddress')
      this.$root.$emit('connect', this.ethAddress);
      //window.web3 = await Moralis.enableWeb3(this.options).catch(error => this.enableWeb3Error(error))
      //console.log(Moralis.Web3.activeWeb3Provider)
      //console.log(Moralis.Web3.activeWeb3Provider.isActivated)
      //console.log("window.web3", window.web3)


      // do stuff with the user
    }


  },
  methods: {
    async authenticate() {
      if (!await Moralis.isMetaMaskInstalled())
      {
        console.log("this.options.provider",this.options.provider)
        await Moralis.enableWeb3({
          throwOnError: true,
          provider: 'walletconnect',
          projectId: '1a4fc09ad5d7117823ca98cd254b8825',
          chainId: 56
        });
      } else {
        await Moralis.enableWeb3({
          throwOnError: true,
        });
      }



      const { account, chainId } = Moralis;

      if (!account) {
        throw new Error(
            "Connecting to chain failed, as no connected account was found"
        );
      }
      if (!chainId) {
        throw new Error(
            "Connecting to chain failed, as no connected chain was found"
        );
      }

      //console.log(account)
      const { message } = await Moralis.Cloud.run("requestMessage", {
        address: account,
        chain: parseInt(chainId, 16),
        network: "evm",
      });
      //console.log(message)
      this.options.signingMessage = message
      this.user = await Moralis.authenticate(this.options).catch(error => {
        this.authenticateError(error);
      })
      //console.log("activeWeb3Provider",await Moralis.Web3.activeWeb3Provider)
      if (this.user) {
        this.ethAddress = this.user.get('ethAddress')
        this.connected = true
        this.isAuthenticated = true
        this.$root.$emit('connect', this.ethAddress);
        console.log(window.location.pathname.indexOf("/profile"));
        if(window.location.pathname.indexOf("/profile") == -1 )  
        window.location.href = window.location.origin + "/profile/" + this.ethAddress.toString().toLowerCase().substr(0, 42)
        //window.web3 = await Moralis.enableWeb3(this.options).catch(error => this.enableWeb3Error(error))
        //console.log("window.web3",window.web3)
      }
      // await Moralis.enableWeb3(this.options.provider ? { provider: "walletconnect" } : {}).then(async (res) => {
      //   window.web3 = res
      //   window.accounts = await res.eth.getAccounts();
      //   console.log(window.accounts);
      //   window.chainId=res.utils.toHex(await Moralis.getChainId())
      //   //localStorage.setItem('connected','true');
      //   //localStorage.setItem('accountInfo', window.accounts[0]);
      //   //this.ethAddress = window.accounts[0];
      //   this.connected = true;
      //   document.getElementById("errormsg").innerHTML = "";
      //
      //
      //
      // }).catch(error => enableWeb3Error(error, this));

    },
    async disconnect() {
      try {
        Moralis.User.logOut()
        this.connected = false;
        this.ethAddress = '';
        this.$root.$emit('disconnect', this.ethAddress);
        //await provider.deactivate();
      } catch (e) {
        console.log(e)
      }
    },
    async authenticateError(error) {
      if (error.message !== "Non ethereum enabled browser") {
        console.log(error.message)
        document.getElementById("errormsg").innerHTML = error.message;
      } else {
        this.options.provider = "walletconnect"
        this.options.projectId = '1a4fc09ad5d7117823ca98cd254b8825'
        this.option.chainId = 56
        this.authenticate()
      }
    },
    gotoProfile(){
       window.location.href = window.location.origin + "/profile/" + this.ethAddress.toString().toLowerCase().substr(0, 42);
    }
  }
};
</script>
<style scoped>
.brand img {
  height: 62px;
  width: auto;
}

.img-sec img {
  width: 60px;
  height: 45px;
}
.faq-section {
    display: flex;
    align-items: center;
}
.connect-section {
  display: flex;
  color: #fff;
  border-radius: 8px;
}

.img-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;

}

p#errormsg {
  margin-bottom: 0px;
}

.public-add {
  margin-bottom: 0px;
  cursor: pointer;
}
.public-add:hover{
  text-decoration: underline;

}

.public-address {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.navbar-container {
  max-width: 1220px;
}

.btn-connect {
  border: 2px solid;
  padding: 5px 37px;
  border-radius: 10px;
  color: #fff;
  font-weight: 800;
}

.main-navbar {
  position: sticky;
  top: 0;
  z-index: 100;
}
@media screen and (max-width: 800px) {
.connect-section{
  justify-content: flex-end;
}
}
</style>
