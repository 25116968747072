import Moralis from "moralis-v1";
// eslint-disable-next-line no-unused-vars
import EthereumProvider from '@walletconnect/ethereum-provider'

Moralis.start({
    serverUrl: process.env.VUE_APP_MORALIS_SERVER_URL,
    appId: process.env.VUE_APP_MORALIS_APP_ID
});



export default Moralis;